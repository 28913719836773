import React from 'react';
import './GC.css';

const Item = props => (
    <div {...props} v='' fgc='' s='' className={props['l'] ? `itemicon-large ${props['active']}` : `itemicon ${props['active']}`}>{props['name'] ? (
        <div className='itemimg'>
            <img src={`https://helpgc.ru/img/${props.children}.png`} alt={props['name']} />
            <div className="itemcount">{props['s'] > 1 ? props['s'] : (props['s'].length > 1 && props['s'] !== 1) ? '...' : ''}</div> 
            {props.dc === '2' ? (
                <div style={{zIndex:99}} className="tooltip">
                    <p className="gci-name">
                        <font className={`GC-k-${props.v[3].hasOwnProperty('k') ? props.v[3].k:''}`}>
                            <font className={`GC-c-${props.v[3].hasOwnProperty('c') ? props.v[3].c:''}`}>
                                {props['name']}
                            </font>
                        </font> <font className={`GC-cd`}>{`(ID:${props.children})`}</font>
                    </p>
                    <div className="grt">
                        <p className={`GC-k-${props.v[3].hasOwnProperty('k') ? props.v[3].k:' --none'} --t`}>
                            {props.v[3].hasOwnProperty('s') ? <font className={`GC-cd`}>, </font>:''}<font className={`GC-s-${props.v[3].hasOwnProperty('s') ? props.v[3].s:''} --t`}></font>
                        </p>
                        <p className={`GC-c-${props.v[3].hasOwnProperty('c') ? props.v[3].c:' --none'} --t`}></p>
                        <p className={`GC-m${props.v[3].hasOwnProperty('m') ? '-t':' --none'} --t`}>
                            {props.v[3].hasOwnProperty('m') ? 
                                (<font className={`GC-organ-${props.v[3].m}`} >
                                    <font className={`GC-j-${props.v[3].hasOwnProperty('j') ? '0':' --none'} GC-jj-${props.v[3].hasOwnProperty('j') ? props.v[3].j[1]:' --none'} --t`}></font>
                                </font>):
                                ('')
                            }
                        </p>
                        <p className={`GC-m${props.v[3].hasOwnProperty('m') ? ' --none':''}`}>
                            <font className={`GC-j-${props.v[3].hasOwnProperty('j') ? props.v[3].j[0]:' --none'} GC-jj-${props.v[3].hasOwnProperty('j') ? props.v[3].j[1]:' --none'} --t`}></font>
                        </p>
                        <p className={`GCC-${props.v[3].hasOwnProperty('ac1') ? props.v[3].ac1:' --none'}`}>{`${props.v[3].hasOwnProperty('a1') ? props.v[3].a1:''}`}</p>
                        <p className={`GCC-${props.v[3].hasOwnProperty('ac2') ? props.v[3].ac2:' --none'}`}>{`${props.v[3].hasOwnProperty('a2') ? props.v[3].a2:''}`}</p>
                        <p className={`GCC-${props.v[3].hasOwnProperty('ac3') ? props.v[3].ac3:' --none'}`}>{`${props.v[3].hasOwnProperty('a3') ? props.v[3].a3:''}`}</p>
                        <p className={`GCC-${props.v[3].hasOwnProperty('ac4') ? props.v[3].ac4:' --none'}`}>{`${props.v[3].hasOwnProperty('a4') ? props.v[3].a4:''}`}</p>
                    </div>
                    <p className={`GC-p-${props.v[3].hasOwnProperty('p') ? 't':' --none'} --t`}>{props.v[3].p}<font className={`GC-cd`}> / </font>{props.v[3].p}
                    {props.v[3].hasOwnProperty('p2') && 
                        <font className={`GC-cd`}>
                            {` (`}
                            {props.v[3].p2.map( (item, i) => <font className="comma" key={i} >{`+${item}`}</font> )}
                            {`)`}
                        </font>
                    }
                    </p>
                    <p className="GC-count">{props['s'] > 1 ? props['s'] : (props['s'].length > 1 && props['s'] !== 1) ? props['s'] : 1}</p>
                    <p className={`GC-g${props.v[3].hasOwnProperty('g') ? '-t':' --none'} --t`}></p>
                    <p className={`GC-d${props.v[3].hasOwnProperty('d') ? '-t':' --none'} --t`}></p>
                    <p className={`GC-e${props.v[3].hasOwnProperty('e') ? '-t':' --none'} --t`}></p>
                </div>
            ):(
                <div style={{zIndex:99}} className="tooltip">
                    <p className="gci-name">{props['name']} <font className={`GC-cd`}>{`(ID:${props.children})`}</font></p>
                    <p className="GC-count">{props['s'] > 1 ? props['s'] : (props['s'].length > 1 && props['s'] !== 1) ? props['s'] : 1}</p>
                </div>
            )}
        </div>
    ) : (
        <div className='itemimg'>
            <img src={`https://helpgc.ru/img/${props.children}.png`} alt='' />
        </div>
    )}</div>
);

export default Item;